<template>
    <div class="citys">
        <top-user-bar
                :userPhoto="$store.getters.userinfo.avatar"
                :userName="$store.getters.userinfo.nickName"
                :city="$store.getters.city.name "
        ></top-user-bar>

        <template v-if="$store.getters.city.id">
            <h2>当前/已选站点</h2>
            <div class="cellbox current">
                <div class="van-cell">{{ $store.getters.city.name }}</div>
            </div>

        </template>

<!--        <h2>热门站点</h2>-->
<!--        <div class="cellbox">-->
<!--            <div class="van-cell" v-for="city in hotcitylist" :title="city" :key="city.index">{{ city }}</div>-->
<!--        </div>-->

        <van-index-bar>
            <div v-for="item in citys" :key="item.index">
                <van-index-anchor :index="item.index"/>
                <div class="cellbox">
                    <van-cell v-for="city in item.list" :title="city.label" :key="city.index" @click="choseSite(city.value, city.label)"/>
                </div>
            </div>


        </van-index-bar>

    </div>
</template>

<script>
  // @ is an alias to /src
  import TopUserBar from "@/components/TopUserBar.vue";
  import {getSites} from "@/api/system";

  export default {
    name: "citys",
    data () {
      return {
        userInfo: {
          photoUrl: require("@/assets/defaultuserpic.png"),
          userName: "小丸子",
          city: "北京站",
        },
        // hotcitylist: [
        //   '北京站', '北京站', '北京站'
        // ],
        citys: [],
      }
    },
    methods: {
      loadSites() {
        getSites().then(data => {
          if (data.success = true) {
            const cities = [];
            const keys = Object.keys(data.data);
            keys.forEach(item => {
              cities.push({
                index: item,
                list: data.data[item]
              })
            });
            this.citys = cities;
          }
        })
      },
      choseSite(id, name) {
        this.$store.commit('SET_CITY', {id, name});
        this.$router.back();
      }
    },
    created() {
      this.loadSites()
    },
    components: {
      "top-user-bar": TopUserBar,
    },
  };
</script>
<style lang="scss">
    .citys {
        h2 {
            font-size: 14px;
            font-weight: normal;
            text-align: left;
            padding: 0 15px;
            height: 32px;
            line-height: 32px;
        }
    }

    .cellbox {
        display: flex;
        padding: 10px;
        background: #fff;
        flex-wrap: wrap;

        .van-cell {
            border: 1px solid #ddd;
            border-radius: 4px;
            margin: 5px;
            width: 22%;
            padding: 5px 10px;
            text-align: center;
            justify-content: center;
        }
    }

    .current .van-cell {
        border-color: rgb(41, 141, 248);
        color: rgb(41, 141, 248);
    }

    .van-index-anchor {
        text-align: left;
    }
</style>
